import { useParams } from "react-router-dom";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { useEffect, useState } from "react";
import "../../../assets/styles/preLaunchRegistrationPromotion/preLaunchRegistrationPromotion.scss";
import PreLaunchRegistrationConfirmationModal from "./PreLaunchRegistrationConfirmationModal";
import PreLunchRegistrationForm, {
    IPreLunchRegistrationFormData
} from "./PreLunchRegistrationForm";
import { sendEmails } from "../service/EmailService";
import { useLeadReceiptsInfo } from "../service/LeadReceiptService";
import { ILeadReceipt } from "../service/dtos";
import { convertLeadReceiptToFormData } from "../utils/mapper";

interface IPreLunchRegistrationFormModalProps {
    popupOpen: boolean;
    setPopupOpen: (open: boolean) => void;
    onEmailSend: () => void;
    onEmailError: () => void;
}

/**
 *  Pre-Registration form popup for anfragen manager
 */

function PreLunchRegistrationFormModal({
    popupOpen,
    setPopupOpen,
    onEmailSend,
    onEmailError
}: IPreLunchRegistrationFormModalProps) {
    const { supplierId } = useParams() as { supplierId: string };
    const [selectedContactId, setSelectedContactId] = useState<string>("");
    const [confirmationModel, setConfirmationModel] = useState<boolean>(false);

    const { data: leadReceiptsInfo, isSuccess } =
        useLeadReceiptsInfo(supplierId);

    // Automatically select the first contact when data is loaded
    useEffect(() => {
        if (
            isSuccess &&
            leadReceiptsInfo?.waitingListBookingLeadReceipts.length > 0
        ) {
            const firstContactId =
                leadReceiptsInfo.waitingListBookingLeadReceipts[0].id;
            setSelectedContactId(firstContactId);
        }
    }, [isSuccess, leadReceiptsInfo]);

    function onSuccess() {
        setConfirmationModel(true);
        setPopupOpen(false);
        onEmailSend();
    }

    function onError() {
        setPopupOpen(false);
        onEmailError();
    }

    // Function to handle form submission
    function updateContactInfoOnSubmit(
        formData: IPreLunchRegistrationFormData
    ) {
        sendEmails(formData, onSuccess, onError);
    }

    // Handle contact person dropdown selection
    const handleContactChange = (event: SelectChangeEvent<string>) => {
        setSelectedContactId(event.target.value);
    };

    // Function to check if there are multiple contact persons
    function hasMultipleContactPersons(): ILeadReceipt[] | false {
        if (
            isSuccess &&
            leadReceiptsInfo &&
            leadReceiptsInfo.waitingListBookingLeadReceipts.length > 1
        ) {
            return leadReceiptsInfo.waitingListBookingLeadReceipts;
        }
        return false;
    }

    return (
        <div>
            <Modal
                className="modal-container"
                open={popupOpen}
                aria-labelledby="pre-lunch-registration-popup"
                aria-describedby="pre-lunch-registration-popup-information"
            >
                <div className="modal-body">
                    <div
                        className="icon icon-close"
                        onClick={() => setPopupOpen(false)}
                    ></div>
                    <h2 id="pre-lunch-registration-title">
                        Melden Sie sich hier für eine kostenlose Live
                        Demonstration für Ihren Anfragen-Manager an
                    </h2>
                    <div>
                        {hasMultipleContactPersons() && (
                            <div className="mb-20">
                                <div className="col-7 label-in-row-desktop contact-person-dropdown-form">
                                    <label className="bolder">
                                        Ansprechpartner:
                                    </label>
                                    <FormControl
                                        fullWidth
                                        margin="normal"
                                        className="mtb-15"
                                    >
                                        <InputLabel id="contact-dropdown-label">
                                            Ansprechpartner
                                        </InputLabel>
                                        <Select
                                            labelId="contact-dropdown-label"
                                            className="contact-person-dropdown"
                                            value={selectedContactId}
                                            onChange={handleContactChange}
                                            label="Ansprechpartner"
                                        >
                                            {leadReceiptsInfo!.waitingListBookingLeadReceipts.map(
                                                (receipt) => (
                                                    <MenuItem
                                                        key={receipt.id}
                                                        value={receipt.id}
                                                    >{`${receipt.email}`}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <hr />
                            </div>
                        )}
                        <PreLunchRegistrationForm
                            key={selectedContactId}
                            PreLunchRegistrationFormData={convertLeadReceiptToFormData(
                                leadReceiptsInfo?.waitingListBookingLeadReceipts.find(
                                    (x) => x.id === selectedContactId
                                ) as ILeadReceipt
                            )}
                            onSubmit={updateContactInfoOnSubmit}
                        />
                    </div>
                </div>
            </Modal>

            <PreLaunchRegistrationConfirmationModal
                popUpOpen={confirmationModel}
                setPopUpOpen={setConfirmationModel}
            />
        </div>
    );
}

export default PreLunchRegistrationFormModal;
